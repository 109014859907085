import React, { useMemo, useState } from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Select from '../../molecules/Inputs/Select/Select';
import {
  coreSubscriptionOptions,
  discountTypeOptions,
  freeAddOnOptions,
  paidAddOnOptions,
  paymentTermOptions,
} from './data';

const ProductSelectionForm = ({ onNext }) => {
  const [paymentTerm, setPaymentTerm] = useState('');
  const [coreSubscription, setCoreSubscription] = useState('');
  const [paidAddOns, setPaidAddOns] = useState([]);
  const [freeAddOns, setFreeAddOns] = useState([]);
  const [discountType, setDiscountType] = useState('');
  const [error, setError] = useState('');

  const filteredCoreSubscriptionOptions = useMemo(() => {
    return paymentTerm
      ? coreSubscriptionOptions.filter(
          option => option.monthly === (paymentTerm === 'Monthly')
        )
      : coreSubscriptionOptions;
  }, [paymentTerm]);

  const filteredPaidAddOnOptions = useMemo(() => {
    return paymentTerm
      ? paidAddOnOptions.filter(
          option => option.monthly === (paymentTerm === 'Monthly')
        )
      : paidAddOnOptions;
  }, [paymentTerm]);

  const filteredFreeAddOnOptions = useMemo(() => {
    return paymentTerm
      ? freeAddOnOptions.filter(
          option => option.monthly === (paymentTerm === 'Monthly')
        )
      : freeAddOnOptions;
  }, [paymentTerm]);

  const handleNext = () => {
    const getOptionByHeading = (options, heading) => {
      const matchingOption = options.find(option => option.heading === heading);

      return JSON.parse(JSON.stringify(matchingOption));
    };

    if (paymentTerm && coreSubscription && discountType) {
      setError('');
      // Convert the selected string values to their respective objects
      onNext({
        paymentTerm: getOptionByHeading(paymentTermOptions, paymentTerm),
        coreSubscription: getOptionByHeading(
          coreSubscriptionOptions,
          coreSubscription
        ),
        paidAddOns: paidAddOns.map(addOn =>
          getOptionByHeading(paidAddOnOptions, addOn)
        ),
        freeAddOns: freeAddOns.map(addOn =>
          getOptionByHeading(freeAddOnOptions, addOn)
        ),
        discountType: getOptionByHeading(discountTypeOptions, discountType),
      });
    } else {
      setError('Please complete all the required fields!');
    }
  };

  return (
    <div className="form">
      <p className="form-kicker">Step One</p>
      <h2 className="form-title">Product Selection</h2>

      <div className="form-content">
        <div>
          <label htmlFor="payment-terms">
            Payment Terms
            <abbr title="required" className="required">
              *
            </abbr>
          </label>
          <Select
            id="payment-terms"
            items={paymentTermOptions}
            menuItemSize="small"
            name="payment-terms"
            placeholder="Select one"
            setValue={value => {
              setPaymentTerm(value);
              // Reset dependent fields
              setCoreSubscription('');
              setPaidAddOns([]);
              setFreeAddOns([]);
            }}
            value={paymentTerm}
          />
        </div>

        <div>
          <label htmlFor="core-subscription">
            Core Subscription
            <abbr title="required" className="required">
              *
            </abbr>
          </label>
          <Select
            disabled={!paymentTerm}
            id="core-subscription"
            items={filteredCoreSubscriptionOptions}
            menuItemSize="small"
            name="core-subscription"
            placeholder="Select one"
            setValue={setCoreSubscription}
            value={coreSubscription}
          />
        </div>

        <div>
          <label className="multi" htmlFor="paid-add-ons">
            Paid Add-Ons
            {paidAddOns.length !== 0 && (
              <span className="count">
                {paidAddOns.length} item{paidAddOns.length > 1 && 's'} selected
              </span>
            )}
          </label>
          <Select
            disabled={!paymentTerm}
            id="paid-add-ons"
            items={filteredPaidAddOnOptions}
            menuItemSize="small"
            multiple
            name="paid-add-ons"
            placeholder="Select all that apply"
            setValue={setPaidAddOns}
            value={paidAddOns}
          />
        </div>

        <div>
          <label className="multi" htmlFor="free-add-ons">
            Free Add-Ons
            {freeAddOns.length !== 0 && (
              <span className="count">
                {freeAddOns.length} item{freeAddOns.length > 1 && 's'} selected
              </span>
            )}
          </label>
          <Select
            disabled={!paymentTerm}
            id="free-add-ons"
            items={filteredFreeAddOnOptions}
            menuItemSize="small"
            multiple
            name="free-add-ons"
            placeholder="Select all that apply"
            setValue={setFreeAddOns}
            value={freeAddOns}
          />
        </div>

        <div>
          <label htmlFor="discount-type">
            Discount Type
            <abbr title="required" className="required">
              *
            </abbr>
          </label>
          <Select
            id="discount-type"
            items={discountTypeOptions}
            menuItemSize="small"
            name="discount-type"
            placeholder="Select one"
            setValue={setDiscountType}
            value={discountType}
          />
        </div>

        {error && <p className="text-danger fs-16">{error}</p>}

        <CallToAction
          className="w-100"
          customHandleClick={handleNext}
          size="lg"
          value="Next"
          variant="secondary"
        />
      </div>
    </div>
  );
};

export default ProductSelectionForm;
